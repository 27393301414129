body {
  margin: 0;
  background-color: #3c9f9c;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Liberation Sans', sans-serif;
}

.App {
  background-color: #3c9f9c;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Liberation Sans', sans-serif;
}

.tan {
  background-color: #efd8a1;
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 5%;
  border-radius: 10px;
}

h1 {
  -webkit-text-stroke: 0.75px black;
  color: #efac28;
}

h2 {
  -webkit-text-stroke: .75px black;
  color: #efac28;
  margin-top: -20px;
  margin-bottom: 5px;
}

.navbar {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.all-players {
  /* display: flex; */
}

.player-icon {
  width: 20%;
  margin-left: 5%;
  margin-top: 5%;
  margin-right: 0%;
  display: inline-block;
}

.player-name {
  width: 40%;
  display: inline-block;
  margin-left: 5%;
  /* background-color: #efb775; */
  background-color: #efe7d9;
  padding: 3px;
}

.player-name:hover {
  background-color: 
}

.player-points {
  width: 10%;
  font-size: 1.5em;
  display: inline-block;
  text-align: right;
  margin-left: 5%;
}

.points-form {
  margin-left: 20%;
  margin-right: auto;
  width: fit-content;
}

#points-val {
  width: 10%;
  display: inline-block;
}

.horizontal-rule {
  width: 85%;
  border-color: #300f0a;
}

input[type=number] {
  -moz-appearance:textfield;
}

.button-3 {
  margin-left: 5%;
  appearance: none;
  background-color: #3c9f9c;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #276468;
}

.button-3:focus {
  box-shadow: #276468 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #276468;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.button-4 {
  margin-left: 5%;
  appearance: none;
  background-color: #ef692f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-4:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-4:hover {
  background-color: #d1512f;
}

.button-4:focus {
  box-shadow: #d1512f 0 0 0 3px;
  outline: none;
}

.button-4:disabled {
  background-color: #aa4a3f;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-4:active {
  background-color: #d1512f;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}